
import {mapGetters, mapMutations} from 'vuex';
import { USER } from 'razlet-sdk/lib/constants/roles';
import ReturnModal from 'razlet-sdk/lib/components/avia/return/return-modal';
import {IS_LOGGED, BALANCE} from 'razlet-sdk/lib/store/auth/getters.type';
import {COUPONS} from 'razlet-sdk/lib/store/account/events/getters.type';
import AuthModal from 'razlet-sdk/lib/components/auth/auth-modal';
import BalanceCard from 'razlet-sdk/lib/components/account/balance-card';
import ContractsModal from 'razlet-sdk/lib/components/auth/contracts-modal';
import GateNotificationTooltip from 'razlet-sdk/lib/components/gate/notification/tooltip';
import {lockScroll, unlockScroll} from 'razlet-sdk/lib/utils/lock-scroll';
import IconKgWhiteLogo from '~/components/icons/icon-kg-white-logo';
import snowBg from '~/assets/img/snow-bg.png';
import GoldTicketIcon from '~/components/icons/ticket';
import RotatingCoin from '~/components/rotating-coin';
import megacomSrc from '../static/img/megacom_logo.webp';
import oSrc from '../static/img/o_logo.webp';
import whatsappSrc from '../static/img/whatsapp_logo.webp';

export default {
  name: 'mobile-header',
  components: {
    BalanceCard,
    AuthModal,
    IconKgWhiteLogo,
    ReturnModal,
    ContractsModal,
    GoldTicketIcon,
    RotatingCoin,
    GateNotificationTooltip,
  },
  data() {
    return {
      isShowedMenu: false,
      isShowedLogin: false,
      isReturnWindowOpened: false,
      isSwitchContractModal: false,
      typeReturn: null,
      snowBg,
      contacts: [
        { 
          href: 'tel: +996709633333',
          text: '+996 (709) 63-33-33',
          img: oSrc,
        },
        { 
          href: 'tel: +996559633333',
          text: '+996 (559) 63-33-33',
          img: megacomSrc,
        },
        { 
          href: 'https://api.whatsapp.com/send?phone=996709633333',
          text: '+996 (709) 63-33-33',
          img: whatsappSrc,
        },
      ],
      bonusStatuses: [
        'silver',
        'gold',
        'platinum',
      ],
    };
  },
  computed: {
    ...mapGetters('auth', [IS_LOGGED, BALANCE]),
    ...mapGetters('account/events', [COUPONS]),
    userName() {
      const {user} = this.$store.getters;
      if (typeof user === 'undefined' || user.token === null) {
        return '';
      }
      return `${user.model.surname} ${user.model.name}`;
    },
    filteredMenu() {
      if (!this.isUser || !this.user.model.roles) return this.menu.filter(item => !item.access);
      return this.menu
        .filter(item => this.user.model.roles.includes(item.access) || !item.access)
        .filter(item => item.generals ? item.generals.includes(this.user.model.generalId) : true);
    },
    locales() {
      return this.$i18n.availableLocales;
    },
    menu() {
      return [
        {
          label: this.$t('menu.home'),
          icon: 'rz-icon-flight-start',
          route: 'index',
        },
        {
          label: this.$t('menu.orders'),
          icon: 'rz-icon-datalist',
          route: 'account-orders',
          access: USER,
        },
        {
          label: this.$t('menu.operations'),
          icon: 'rz-icon-clock-arrow',
          route: 'account-operations',
          access: USER,
        },
        {
          label: this.$t('menu.bonuses'),
          icon: 'rz-icon-motivation',
          route: 'account-bonuses',
          access: USER,
        },
        {
          label: this.$t('menu.requests'),
          icon: 'rz-icon-assistant',
          route: 'account-requests',
          access: USER,
        },
        {
          label: this.$t('menu.tickets'),
          icon: 'rz-icon-avia-ticket',
          route: 'account-tickets',
          access: USER,
        },
        {
          label: this.$t('menu.passengers'),
          icon: 'rz-icon-adult',
          route: 'account-passengers',
          access: USER,
        },
        /* {
          label: this.$t('menu.lottery'),
          icon: 'rz-icon-ticket',
          route: 'account-lottery',
          access: USER,
        }, */
      ];
    },
  },
  watch: {
    '$locale': {
      handler(value) {
        this.$cookiz.set('defaultLanguage', value, {path: '/', maxAge: 60 * 60 * 24 * 365});
        this.$bus.$emit('locale-changed');
      },
    },
    currentTheme: {
      handler (value) {
        this.$cookiz.set('currentTheme', value, { path: '/', maxAge: 60 * 60 * 24 * 365 });
        if (process.client) this.changeColorScheme();
        this.$bus.$emit('theme-changed', value);
      },
    },
  },
  methods: {
    ...mapMutations(['setTheme']),
    showMenu() {
      lockScroll();
      this.isShowedMenu = true;
    },
    closeMenu() {
      unlockScroll();
      this.isShowedMenu = false;
    },
    showLoginMenu() {
      this.isShowedLogin = true;
      this.closeMenu();
    },
    profileAction() {
      if (this.showLogin) {
        if (this.isUser) this.goTo('account-profile');
        else this.showLoginMenu();
      } else {
        window.location.href = 'https://agent.razlet.kg';
      }
    },
    returnTicket() {
      if (this.isAgent) {
        this.$router.push({ name: 'account-requests-create', query: { template: 'empty', types: 'tickets' } });
      } else {
        this.typeReturn = 'exchange';
        this.isReturnWindowOpened = true;
      }
      this.closeMenu();
    },
    findOrder() {
      this.typeReturn = 'search';
      this.closeMenu();
      this.isReturnWindowOpened = true;
    },
    goTo(route) {
      this.closeMenu();
      this.$router.push({name: route});
    },
    logout() {
      this.$bus.$emit('logout');
      this.closeMenu();
    },
  },
};
