
import { mapGetters, mapMutations } from 'vuex';
import ReturnModal from 'razlet-sdk/lib/components/avia/return/return-modal';
import {COUPONS} from 'razlet-sdk/lib/store/account/events/getters.type';
import LoginMenu from '~/components/login-menu';
import IconKgWhiteLogo from '~/components/icons/icon-kg-white-logo';
import snowBg from '~/assets/img/snow-bg.png';
import RotatingCoin from '~/components/rotating-coin';
import GoldTicketIcon from '~/components/icons/ticket';

export default {
  components: {
    LoginMenu,
    IconKgWhiteLogo,
    ReturnModal,
    GoldTicketIcon,
    RotatingCoin,
  },
  data() {
    return {
      isPassengersDropdownOpened: false,
      isReturnWindowOpened: false,
      typeReturn: null,
      snowBg,
      bonusStatuses: [
        'silver',
        'gold',
        'platinum',
      ],
    };
  },
  computed: {
    ...mapGetters('account/events', [COUPONS]),
    locales() {
      return this.$i18n.availableLocales;
    },
  },
  watch: {
    '$locale': {
      handler(value) {
        this.$cookiz.set('defaultLanguage', value, {path: '/', maxAge: 60 * 60 * 24 * 365});
        this.$bus.$emit('locale-changed');
      },
    },
    currentTheme: {
      handler (value) {
        this.$cookiz.set('currentTheme', value, { path: '/', maxAge: 60 * 60 * 24 * 365 });
        if (process.client) this.changeColorScheme();
        this.$bus.$emit('theme-changed', value);
      },
    },
  },
  methods: {
    ...mapMutations(['setTheme']),
    hidePassengersDropdown() {
      this.isPassengersDropdownOpened = false;
    },
    returnTicket() {
      if (this.isAgent) {
        this.$router.push({ name: 'account-requests-create', query: { template: 'empty', types: 'tickets' } });
      } else {
        this.typeReturn = 'exchange';
        this.isReturnWindowOpened = true;
      }
    },
    findOrder() {
      this.typeReturn = 'search';
      this.isReturnWindowOpened = true;
    },
    goToAgent() {
      window.open('https://agent.razlet.kg', '_blank');
    },
  },
};
