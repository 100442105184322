

const STORAGE_KEY = 'showBirthdayMessage';

export default {
  name: 'birthday-message',
  data() {
    return {
      isShow: false,
    };
  },
  computed: {
    isBirthdateMissing() {
      return this.isUser && this.user.model && (!this.user.model.birthday || !this.user.model.birthday.length);
    },
  },
  mounted() {
    if (typeof this.getCookie() === 'undefined') this.setCookie(true, { maxAge: 10000000 });
    this.isShow = !!this.getCookie();
  },
  methods: {
    hideMessage() {
      this.setCookie(false, { maxAge: 86400 });
      this.isShow = false;
    },
    setCookie(value, opts = {}) {
      this.$cookiz.set(STORAGE_KEY, value, opts);
    },
    getCookie() {
      return this.$cookiz.get(STORAGE_KEY);
    },
  },
};
