
export default {
  name: 'rotating-icon',
  props: {
    text: {
      type: [String, Number],
      default: 'R',
    },
    type: {
      type: String,
      default: 'gold',
    },
  },
};
