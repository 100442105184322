export default {
  data() {
    return {
      root: null,
    };
  },
  mounted() {
    if (this.isDev) {
      if (this.currentTheme === 'light') this.setVars();
      this.$bus.$on('theme-changed', (value) => {
        if (value === 'dark') this.clearVars();
        else this.setVars();
      });
    }
  },
  beforeDestroy() {
    if (this.isDev) this.$bus.$off('theme-changed');
  },
  methods: {
    setVars() {
      this.root = document.documentElement;
      if (localStorage.getItem('--bg-page')) this.setVar('--bg-page', localStorage.getItem('--bg-page'));
      else this.setVar('--bg-page', '#F5F5F5');

      if (localStorage.getItem('--bg')) this.setVar('--bg', localStorage.getItem('--bg'));
      else this.setVar('--bg', '#FFFFFF');

      if (localStorage.getItem('--text')) this.setVar('--text', localStorage.getItem('--text'));
      else this.setVar('--text', '#363636');

      if (localStorage.getItem('--main')) this.setVar('--main', localStorage.getItem('--main'));
      else this.setVar('--main', '#1b4c80');

      if (localStorage.getItem('--second')) this.setVar('--second', localStorage.getItem('--second'));
      else this.setVar('--second', '#f74b1f');

      if (localStorage.getItem('--border-input')) this.setVar('--border-input', localStorage.getItem('--border-input'));
      else this.setVar('--border-input', '2px');

      if (localStorage.getItem('--border-radius')) this.setVar('--border-radius', localStorage.getItem('--border-radius'));
      else this.setVar('--border-radius', '5px');

      if (localStorage.getItem('--bg-index-pricing')) this.setVar('--bg-index-pricing', localStorage.getItem('--bg-index-pricing'));
      else this.setVar('--bg-index-pricing', 'linear-gradient(79.74deg,#0076cc 1.85%,#3698e0 98.62%)');
    },
    clearVars() {
      this.clearVar('--bg-page');
      this.clearVar('--bg');
      this.clearVar('--text');
      this.clearVar('--main');
      this.clearVar('--second');
      this.clearVar('--brder-input');
      this.clearVar('--border-radius');
      this.clearVar('--bg-index-pricing');
    },
    setVar(variable, value) {
      this.$nextTick(() => {
        document.getElementsByClassName((`theme--${this.currentTheme}`))[0].style.setProperty(variable, value);
        localStorage.setItem(variable, value);
      });
    },
    clearVar(variable) {
      this.$nextTick(() => {
        document.getElementsByClassName((`theme--${this.currentTheme}`))[0].style.removeProperty(variable);
      });
    },
  },
};