import { render, staticRenderFns } from "./desktop-header.vue?vue&type=template&id=3247a342"
import script from "./desktop-header.vue?vue&type=script&lang=js"
export * from "./desktop-header.vue?vue&type=script&lang=js"
import style0 from "./desktop-header.vue?vue&type=style&index=0&id=3247a342&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RzLangSwitcher: require('/opt/razlet.kg/node_modules/razlet-ui/src/components/lang-switcher/index.vue').default,RzIconChat: require('/opt/razlet.kg/node_modules/razlet-ui/src/icons/icon-chat/index.vue').default,RzIconExitBig: require('/opt/razlet.kg/node_modules/razlet-ui/src/icons/icon-exit-big/index.vue').default,RotatingCoin: require('/opt/razlet.kg/src/components/rotating-coin.vue').default,RzTooltipContainer: require('/opt/razlet.kg/node_modules/razlet-ui/src/components/tooltip-container/index.vue').default,RzThemeSwitcher: require('/opt/razlet.kg/node_modules/razlet-ui/src/components/theme-switcher/index.vue').default,RzIconDropArrow: require('/opt/razlet.kg/node_modules/razlet-ui/src/icons/icon-drop-arrow/index.vue').default,RzDropdown: require('/opt/razlet.kg/node_modules/razlet-ui/src/components/dropdown/index.vue').default,LoginMenu: require('/opt/razlet.kg/src/components/login-menu.vue').default,RzContainer: require('/opt/razlet.kg/node_modules/razlet-ui/src/components/container/index.vue').default})
