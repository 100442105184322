

export default {
  name: 'hello-message',
  data() {
    return {
      message: 'Для вашего удобства мы обновили дизайн и стали лучше. Будем рады вашим отзывам :)',
      isShow: false,
    };
  },
  mounted() {
    if (!localStorage.getItem('showHelloMessage')) localStorage.setItem('showHelloMessage', 'true');
    this.isShow = localStorage.getItem('showHelloMessage') === 'true';
  },
  methods: {
    hideMessage() {
      localStorage.setItem('showHelloMessage', 'false');
      this.isShow = false;
    },
  },
};
