
import {mapGetters} from 'vuex';
import GateNotificationTooltip from 'razlet-sdk/lib/components/gate/notification/tooltip';
import GateNotificationCenter from 'razlet-sdk/lib/components/gate/notification/center';
import WidgetComponent from 'razlet-sdk/lib/components/chat/widget/widget-component';
import PricingView from 'razlet-sdk/lib/components/avia/pricing/pricing-view';
import auth from '~/mixins/auth';
import search from '~/mixins/search';
import HelloMessage from '~/components/hello-message';
import FooterBlock from '~/components/footer-block';
import MainHeader from '~/components/main-header';
import CallButton from '~/components/call-button';
import colors from '~/mixins/colors';
import access from '~/mixins/access';

const pricingFormRoutes = [
  'search-uid', 
  'booking-id', 
  'order-id',
];
const withoutContainerRoutes = [
  'all',
  'bad-access',
  'colors', 
  'index', 
  'login', 
  'support-chat', 
  'account-lottery-enter',
  'bid-return-id',
  'bid-return-create-id',
  'content-agmnt',
  'content-card-payment',
  'content-contacts',
  'content-offices',
  'content-refund-rules',
  'content-requisite',
  'content-about-us',
  'news-all',
  'news',
  'order-id-refund',
  'order-id-refund-voluntary',
  'policy',
  'notifications-guide',
];

export default {
  components: {
    FooterBlock, 
    PricingView, 
    MainHeader,
    CallButton,
    HelloMessage,
    WidgetComponent,
    GateNotificationTooltip,
    GateNotificationCenter,
  },
  mixins: [auth, search, colors, access],
  computed: {
    ...mapGetters(['isPushRegistered']),
    isRouteWithPricingForm() {
      return pricingFormRoutes.includes(this.$route.name);
    },
    isRouteWithoutContainer() {
      return withoutContainerRoutes.includes(this.$route.name);
    },
    isRouteAccount() {
      return this.$route.name.startsWith('account-');
    },
    widgetAvatars() {
      return {
        '13407': '/avatars/3240.webp',
        '13435': '/avatars/3281.webp',
        '15022': '/avatars/5633.webp',
        '15051': '/avatars/5667.webp',
        '15772': '/avatars/6595.webp',
        '15813': '/avatars/6643.webp',
        '17009': '/avatars/8047.webp',
      };
    },
  },
  watch: {
    '$route': {
      handler(value) {
        if (value) {
          if (this.$refs.widget) this.widgetMounted();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.$store.commit('setMobile', window.innerWidth <= 992);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', () => {
      this.$store.commit('setMobile', window.innerWidth <= 992);
    });
  },
  methods: {
    widgetMounted() {
      if (this.$route.query && this.$route.query.chatId) {
        this.$bus.$emit('widget-open-chat', this.$route.query.chatId, true);
        const query = {...this.$route.query};
        delete query.chatId;
        this.$router.replace({...this.$route, query});
      }
    },
  },
};
